<template>
    <div class="method">
      <div
        class="nav-bar nav-item"
        @mouseleave="navBars()"
        @mouseenter="navBar()"
        @click="work()"
      >
        <span class="iconfont icon-geren"></span>
        <div class="tabs-text" ref="tab">工作台</div>
      </div>
      <div
        v-if="showShoppingCart"
        class="nav-bar nav-item"
        @mouseleave="navItems()"
        @mouseenter="navItem()"
        @click="shoppingCart()"
      >
        <div style="position: relative;">
          <div class="iconfont icon-gouwuche"></div>
          <div class="carnumber" v-if="carnumber > 0">{{ carnumber }}</div>
        </div>

        <div class="tabs-text" ref="tabs">购物车</div>
      </div>
      <div class="nav-bar nav-item"
        @mouseleave="navItemsd()" 
        @mouseenter="navItemsdg()"
        @click="dialogVisible = true"
      >
        <div class="iconfont icon-kehufuwukefu"></div>
        <div class="tabs-text" ref="tabsd">客服</div>
      </div>
      <el-dialog :visible.sync="dialogVisible" width="20%" append-to-body custom-class="floatDialog">
        <div>
            <div style="margin-bottom:5px;">电话1</div>
            <div>05922505012</div>
        </div>
        <div style="margin:15px 0;">
            <div style="margin-bottom:5px;">电话2</div>
            <div>18650006780</div>
        </div>
        <div>
            <div>工作日 上午8：30-12：00，下午13：30-18：00</div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
        </el-dialog>
    </div>
    
</template>

<script>
import { carNumber } from "@/api/api";
export default {
    data() {
        return {
            carnumber: 0,
            dialogVisible: false,
            showShoppingCart: true,
        }
    },
    created(){
        if(location.href.indexOf('/shoppingCart') !== -1){
            this.showShoppingCart = false
        }
        carNumber().then(data => {
            if (data && data.code == "00000") {
                this.carnumber = data.data;
            }
        });
    },
    methods: {
        kefuDialog() {
            this.dialogVisible = true
        },
        //固定左侧栏
        navBar() {
            // this.$refs.tab.style.display = "block";
        },
        navBars() {
            // this.$refs.tab.style.display = "none";
        },
        navItem() {
            // this.$refs.tabs.style.display = "block";
        },
        navItems() {
            // this.$refs.tabs.style.display = "none";
        },
        navItemsdg() {
            // this.$refs.tabsd.style.display = "block";
        },
        navItemsd() {
            // this.$refs.tabsd.style.display = "none";
        },
        work() {
            if(this.$routerWin){
              const { href } = this.$router.resolve({ name: "Personalwork" });
              window.open(href,'_blank');
            } else {
              this.$router.push({ name: "Personalwork" });
            }
        },
        shoppingCart() {
            if(this.$routerWin){
              const { href } = this.$router.resolve({ name: "ShoppingCart" });
              window.open(href,'_blank');
            } else {
              this.$router.push({ name: "ShoppingCart" });
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.method {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  width: 40px;
  height: 50px;

  .nav-bar {
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #7a6f6e;
    margin-bottom: 1px;
    .iconfont {
      font-size: 28px;
      color: #fff;
    }
    .carnumber {
      position: absolute;
      top: 4%;
      right: 10%;
      height: 16px;
      line-height: 16px;
      font-size: 10px;
      transform: none;
      background: #f2270c;
      min-width: 20px;
      color: #fff;
      border-radius: 8px;
    }
  }
  .nav-item {
    position: relative;
    .tabs-text {
      position: absolute;
      background: #7a6f6e;
      color: #fff;
      width: 80px;
      left: -73px;
      top: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      cursor: pointer;
      display: none;
      &:hover {
        color: #cd9f49;
      }
    }
    &:hover .tabs-text {
      display: block;
    }
  }
}
::v-deep.el-dialog__wrapper{
  .floatDialog {
    min-width: 350px;
  }
}
</style>