<template>
  <div class="common_header">
    <iframe
      id="geoPage"
      width="0"
      height="0"
      frameborder="0"
      style=""
      scrolling="no"
      src="https://apis.map.qq.com/tools/geolocation?key=FQOBZ-MWGC6-3HNSW-MFLZ5-HBCEO-3QB37&referer=myapp"
    >
    </iframe>
    <div class="header_top">
      <div class="top_box flex-between">
        <div style="margin-left:10px;">
          <span>欢迎来到禹佳商城</span>
          <span style="padding:0 6px;">|</span>
          <span class="iconfont icon-dizhi"></span>
          <span>{{ city }}</span>
        </div>
        <div class="flex-start">
          <div class="top_inforbox">
            <el-dropdown>
              <div>
                <span class="iconfont icon-yonghu"></span>
                <!-- <span class="user_name">禹佳商贸{{ identity }}</span> -->
                <span class="user_name">{{ userName }}</span>
                <span class="iconfont icon-zhankai"></span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="account()"
                  >个人中心</el-dropdown-item
                >
                <el-dropdown-item @click.native="goOut()"
                  >退出</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-if="authorityJudgment([1, 2, 3, 4, 5])" class="top_inforbox">
            <span class="iconfont icon-dingdan1"></span>
            <span class="user_infor" @click="order()">我的订单</span>
          </div>
          <div class="top_inforbox">
            <span class="iconfont icon-kehufuwukefu"></span>
            <span class="user_infor" @click="openKeFu()">客服</span>
          </div>
          <div class="top_inforbox">
            <span class="iconfont icon-gongzuotai"></span>
            <span class="user_infor" @click="work()">工作台</span>
          </div>
          <div v-if="authorityJudgment([2, 3, 5, 6])" class="top_inforbox">
            <span class="iconfont icon-xiaoxi"></span>
            <span class="user_infor" @click="onNews()">消息 {{$store.state.countMsg.unRead ? `(${$store.state.countMsg.unRead})` : ''}}</span>
          </div>
          <!-- <div class="top_inforbox">
              <span class="iconfont icon-shoucangjia"></span>
              <span class="user_infor">收藏夹</span>
            </div>
            <div class="top_inforbox">
              <span class="iconfont icon-bangzhu"></span>
              <span class="user_infor">帮助</span>
            </div>
            <div class="top_inforbox" style="padding-right:0;">
              <span class="iconfont icon-xiaoxi"></span>
              <span class="user_infor">消息（2）</span>
            </div> -->
        </div>
      </div>
    </div>
    <div class="header_middle flex-between">
      <div class="top_box flex-between">
        <div class="flex-start">
          <img
            class="logo_img"
            src="@/assets/image/logo_1.png"
            alt=""
            @click="gotoindex()"
          />
          <div class="close_text" v-show="seet == 3">结算页</div>
          <div class="close_text" v-show="seet == 4" @click="gotoIndex()">
            回到首页
          </div>
        </div>

        <div class="flex-start">
          <div class="search_box flex-start" v-show="seet != 3">
            <div class="input_box">
              <el-input
                type="text"
                placeholder="请输入搜索商品名称"
                class="search_input"
                v-model="inputName"
                @keyup.enter.native="searchGoods()"
              ></el-input>
              <div class="iconfont icon-sousuo1"></div>
            </div>
            <button
              class="search_btn"
              @click="searchGoods()"
            >
              搜索
            </button>
          </div>
          <div class="shopping_cart" v-show="seet != 2 && seet != 3">
            <div class="shopping_cart_box" @click="shoppingCart()">
              <span class="iconfont icon-gouwuche"></span>
              <span style="margin-left:15px;">购物车</span>
              <div class="carnumber">{{ carnumber }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="seet != 2 && seet != 3">
      <div class="header_all flex-start">
        <div class="allGoods">全部商品</div>
        <div class="tips" v-show="$store.state.headerTips">{{$store.state.headerTips}}</div>
        <!-- <div class="tips">量大价优，具体联系禹佳采购 ，有起订量、指定地区或专供的商品请勿直接下单，先与采购联系！！</div> -->
      </div>
      <div class="header_xian"></div>
    </div>
    <FloatBox ref="floatBox"></FloatBox>
  </div>
</template>

<script>
import FloatBox from "@/components/Layout/floatBox.vue";
import { carNumber,userInfor, countMsg } from "@/api/api";
import { clearLoginInfo } from "@/utils";
import { authorityJudgment } from "@/utils/util"
export default {
  components: {FloatBox},
  data() {
    return {
      authorityJudgment,
      number: "",
      inputName: "",
      pageIndex: 1,
      pageSize: 20,
      // orderBy: "id",
      searchList: [],
      city: "",
      identity: "",
      userName: "",
      carnumber: 0,
    //   newsIndex: 0
      notify: null,
    };
  },
  props: {
    seet: {
      type: String,
      default: ""
    },
    orderBy: {
      type: String,
      default: "_score"
    },

  },
  created(){
    this.Tmap()
    userInfor().then(data => {
      // console.log(data);
      this.userName = data.data.userName
      let identity=data.data.roleList
      localStorage.setItem("identity", JSON.stringify(identity));
      localStorage.setItem("externalId", JSON.stringify(data.data.externalId));
      this.$store.commit("setAcc", data.data.userPhone);
      this.$store.commit("setId", { userId: data.data.userId, externalId: data.data.externalId });
      if(identity.length>1){
        let list =[]
        identity.forEach((item) => {
            list.push(item.roleId)
        });
        if(list.length==4){
          this.identity='管理员'
        }else{
          let a = myfunction(list,1);
          let b = myfunction(list,2);
          let c = myfunction(list,3);
          let d = myfunction(list,4);

          if(a.length && b.length && c.length){
              this.identity='管理员'
          }else if(a.length && b.length && d.length){
              this.identity='管理员'
          }else if(a.length && c.length && d.length){
              this.identity='管理员'
          }else if(b.length && c.length && d.length){
              this.identity='总部审批员'
          }else if(a.length && b.length){
              this.identity='管理员'
          }else if(a.length && c.length){
              this.identity='管理员'
          }else if(a.length && d.length){
              this.identity='管理员'
          }else if(b.length && c.length){
              this.identity='项目审批员'
          }else if(b.length && d.length){
              this.identity='总部审批员'
          }else if(c.length && d.length){
              this.identity='总部审批员'
          }else if(a.length){
              this.identity='管理员'
          }else if(b.length){
              this.identity='项目审批员'
          }else if(c.length){
              this.identity='采购员'
          }else if(d.length){
              this.identity='总部审批员'
          }
          // eslint-disable-next-line no-inner-declarations
          function myfunction(arr,t){
                function checkAult(p){
                    return p==t
                }
                return arr.filter(checkAult)
            }
          }
        }else{
            identity.forEach(item => {
                 if(item.roleId=='3'){
                    this.identity='采购员'
                }else if(item.roleId=='2'){
                    this.identity='项目审批员'
                }else if(item.roleId=='4'){
                    this.identity='总部审批员'
                }else{
                    this.identity='管理员'
                }
            });
        
        }
      // if(identity.length>1){
      //   identity.forEach(item => {
      //     if(item.roleId=='3'){
      //       this.identity=item.roleName
      //     }else if(item.roleId=='2'){
      //       this.identity=item.roleName
      //     }else if(item.roleId=='4'){
      //       this.identity=item.roleName
      //     }else if(item.roleId=='1'){
      //       this.identity=item.roleName
      //     }
      //   });
      // }else{
      //   identity.forEach(item => {
      //     this.identity=item.roleName
      //   });
      // }
      carNumber().then((data)=>{
        if(data&&data.code=='00000'){
          this.carnumber=data.data
        }
      })
    });
    this.onCountMsg()
    // setInterval(()=>{
    //   carNumber().then((data)=>{
    //     if(data&&data.code=='00000'){
    //       this.carnumber=data.data
    //     }
    //   })
    // },8000)
  },
  mounted() {
    // this.notify = this.$notify({
    //     title: '温馨提示',
    //     duration: 0,
    //     dangerouslyUseHTMLString: true,
    //     message: `<div style="font-size: 15px;color: #000;">
    //         受降雪及路面结冰影响，北京、天津、河北、山西、黑龙江、山东、河南、陕西等多地区路段封闭，快递时效不保，不接急单，急件请提前计划哦！
    //     </div>`
    // })
  },
  beforeDestroy() {
    if(this.notify) {
        this.notify.close()
    }
  },
  computed: {
    GoodsList: {
      get() {
        return this.$store.state.common.GoodsList;
      },
      set(val) {
        this.$store.commit("common/updategoodsList", val);
      }
    }
  },
  // watch(){
  // let that =this
  // document.onkeypress = function(e) {
  //   let codeKey = document.all ? event.keyCode : e.which;
  //   if (codeKey == 13) {
  //     that.searchGoods()
  //     return false;
  //   }
  // };
  // },
  methods: {
    Tmap() {
      let that = this;
      window.addEventListener(
        "message",
        function(event) {
          if (event.data != null) {
            //这个会获取到四条数据，其中有一条数据为null，然后你如果是用变量去获取，会报错
            if (event.data.module == "geolocation") {
              //去掉一条null后还有一条杂数据，里面没啥东西，然后你要是用变量获取位置还是会报错，所有过滤掉非该类型的数据
              that.city = event.data.city;
            }
          }
        },
        false
      );
    },
    onCountMsg() {
      countMsg().then((data)=>{
        if(data && data.code=='00000'){
        //   this.newsIndex = data.data.unRead
            this.$store.commit("setMsg", data.data);
        }
      })
    },
    searchGoods() {
      if (this.inputName == "") {
        this.$message({
          message: "请先输入搜索商品",
          type: "waring"
        });
      } else {
        let that = this;
        if(this.$routerWin){
          const { href } = that.$router.resolve({ name: "SearchGoods", query: { input: that.inputName } });
          window.open(href,'_blank');
        } else {
          that.$router.push({
            name: "SearchGoods",
            query: { input: that.inputName }
          });
        }
        
      }
    },
    shoppingCart() {
      if(this.$routerWin){
        const { href } = this.$router.resolve({ name: "ShoppingCart" });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "ShoppingCart" });
      }
    },
    account() {
      if(this.$routerWin){
        const { href } = this.$router.resolve({ name: "AccountSettings" });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "AccountSettings" });
      }
    },
    goOut() {
      clearLoginInfo();
    },
    gotoindex() {
      this.$router.push({ name: "Index" });
    },
    order() {
      if(this.$routerWin){
        const { href } = this.$router.resolve({ name: "MyOrder" });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "MyOrder" });
      }
    },
    work() {
      if(this.$routerWin){
        const { href } = this.$router.resolve({ name: "Personalwork" });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "Personalwork" });
      }
    },
    // 消息
    onNews() {
      sessionStorage.setItem('news-activeName', 0)
      if(this.$routerWin){
        const { href } = this.$router.resolve({ name: "news" });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "news" });
      }
    },
    openKeFu() {
      this.$refs.floatBox.kefuDialog()
    },
    
    gotoIndex() {
      this.$router.push({ name: "Index" });
    }
  }
};
</script>

<style lang="scss">
.common_header {
  width: 100%;
}
#geoPage {
  display: none;
}
.top_box {
  width: 70%;
  margin: 0 auto;
}
.header_top {
  height: 42px;
  line-height: 42px;
  background: #c9c9c9;
  font-size: 14px;

  .top_inforbox {
    margin-right: 25px;

    &:hover {
      cursor: pointer;
      color: #cd9f49;
    }

    &:last-child {
      margin-right:0;
    }
  }
  .user_name {
    padding: 0 5px;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-dropdown-menu {
    top: 55px !important;
  }
  .user_infor {
    padding-left: 3px;
  }
}
.header_middle {
  height: 80px;
  margin: 20px 0;
  .logo_img {
    width: 200px;
    height: 45px;
    cursor: pointer;
  }
  .close_text {
    margin-left: 40px;
    font-size: 23px;
    cursor: pointer;
  }
  .search_box {
    // margin-right: 35px;
    .input_box {
      position: relative;
      .icon-sousuo1 {
        position: absolute;
        top: 50%;
        right: 9px;
        font-size: 33px;
        transform: translateY(-50%);
      }
    }
    .el-input__inner {
      width: 346px;
      height: 49px;
      line-height: 49px;
      padding-left: 10px;
      border: 1px solid #797979;
      border-radius: 0;
    }
    .search_btn {
      width: 88px;
      height: 49px;
      line-height: 48px;
      text-align: center;
      background: #cd9f49;
      color: #fff;
      border: 1px solid #797979;
      font-size: 14px;
      &:hover {
        cursor: pointer;
        font-size: 15px;
      }
    }
  }
  .shopping_cart {
    width: 163px;
    height: 48px;
    line-height: 48px;
    margin-left: 35px;
    text-align: center;
    margin-right: 10px;

    .shopping_cart_box {
      position: relative;
      border: 1px solid #797979;

      &:hover {
        cursor: pointer;
        color: #cd9f49;
        border-color: #cd9f49;
      }
    }

    .carnumber {
      position: absolute;
      top: 20%;
      right: 55%;
      height: 16px;
      line-height: 16px;
      font-size: 10px;
      transform: none;
      background: #f2270c;
      min-width: 20px;
      color: #fff;
      border-radius: 8px;
    }
  }
}
.header_all {
  width: 70%;
  margin: 0 auto;
  align-items: flex-end;
  .allGoods {
    width: 193px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #cd9f49;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
  }
  .tips {
    margin: -12px 0 0 10px;
    color: red;
    font-size: 24px;
    font-weight: bold;
    max-width: 610px;
  }
}
.header_xian {
  height: 5px;
  background: #cd9f49;
}
</style>
