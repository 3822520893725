// 权限判断
export function authorityJudgment(data) {
  let arr = localStorage.getItem('identity') ? JSON.parse(localStorage.getItem('identity')) : []
  let isAuthorityJudgment = false
  for (let i = 0; i < arr.length; i++) {
    if (typeof data == "number") {
      if(arr[i].roleId == data){
        isAuthorityJudgment = true
        break
      }
    } else {
      if(data.indexOf(Number(arr[i].roleId)) !== -1){
        isAuthorityJudgment = true
        break
      }
    }
  }
  return isAuthorityJudgment
}